import React from "react"
import Section from "../Elements/Section"
import teleconsultSurvey from "./utils/teleconsultSurvey"
import FormInput from "../Elements/Form/FormInput"

const OtherComponent = ({ details, parentField, values }) => {
  const Component = details?.component || FormInput
  return details?.validation(values[parentField.valueName]) ? (
    <Component {...details} />
  ) : null
}

const OnlineConsultationSection = ({ values }) => {
  return (
    <Section>
      {teleconsultSurvey.map((field) => {
        const Component = field.component || FormInput
        return (
          <section className="mb-2">
            <Component {...field} value={values[field.valueName]} />
            <OtherComponent
              details={field.otherOption}
              parentField={field}
              values={values}
            />
          </section>
        )
      })}
    </Section>
  )
}

export default OnlineConsultationSection
