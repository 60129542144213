import React, { useContext, useEffect } from "react"
import { Formik, Form, Field } from "formik"

import { AppContext } from "../../context/AppContext"
import ActionButtons from "../Elements/ActionButtons"
import { healthSurveyValidationSchema } from "./utils/formData"
import healthSurveyHeaders from "./utils/healthSurveyHeaders.json"

import { navigate } from "gatsby"

import OnlineConsultationSection from "./OnlineConsultationSection"

const HealthSurveyRadio = ({ name, values }) => {
  return healthSurveyHeaders.map((header) => (
    <td align="center">
      <Field
        id={`${name}-${header}`}
        className="is-checkradio is-rtl is-info"
        type="radio"
        name={`${name}`}
        value={header}
        checked={values === header}
      />
      <label for={`${name}-${header}`}></label>
    </td>
  ))
}

const HealthSurvey = () => {
  const { state, dispatch } = useContext(AppContext)
  const { healthSurvey } = state.epharmacy

  useEffect(() => {
    dispatch({
      type: "HIDE_MODAL",
    })
  }, [])
  const handleOnSubmit = async (values) => {
    await dispatch({ type: "SAVE_HEALTH_SURVEY", payload: values })
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, healthSurvey: values },
      },
    })
    await navigate("/epharmacy/checkout")
  }

  return (
    <Formik
      initialValues={{ ...healthSurvey }}
      onSubmit={handleOnSubmit}
      validationSchema={healthSurveyValidationSchema}
    >
      {({ values }) => (
        <Form>
          <section className="mb-2">
            <OnlineConsultationSection values={values.teleconsultSurvey} />
          </section>
          <ActionButtons
            submit={{ label: "Input Details" }}
            back={{ label: "Back", link: "/epharmacy/upload" }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default HealthSurvey
